import React from "react";
import styled from "styled-components";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import { lighten } from "polished";
import Obfuscate from 'react-obfuscate';


const FooterContainer = styled(FullWidthBackground)`
  background-color: ${(props) => props.theme.colors.accents.pearlBush};
`;

const FooterContent = styled(ContentContainer)`
  padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.lg};
  text-align: center;
  color: ${(props) => lighten(0.30, props.theme.colors.base)};
  a {
    color: ${(props) => lighten(0.30, props.theme.colors.base)};
    &:hover {
      color: ${(props) => lighten(0.4, props.theme.colors.base)};
    }
  }

  address{
    font-style: normal;
    display: inline;
  }
`;

export const Footer = ({}) => (
  <FooterContainer>
    <FooterContent>
      <p><a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutzerklärung</a></p>
      <p>
        Mag.<sup>a</sup> Susanne Hoffelner | Psychotherapeutin (Systemische Familientherapie)
        <br />
        <Obfuscate tel="+43 650 540 75 99" /> | <address>Linsengasse 57, 9020 Klagenfurt</address>
      </p>
    </FooterContent>
  </FooterContainer>
);

export default Footer;