import styled from "styled-components";
import media from "styled-media-query";

export const ContentContainer = styled.div`
  max-width: ${(props) => props.theme.layout.containerWidth};
  padding: 6rem 1.25rem;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  ${media.greaterThan("medium")`
      padding: 6rem ${(props) => props.theme.spacing.lg};
  `}
`;

export const FullWidthBackground = styled.div`
  width: 100%;
  margin: auto;
`;
