import React from "react";
import Helmet from "react-helmet";

const siteTitle = "Psychotherapie Mag.a Susanne Hoffelner"
export const Metadata = ({title}) => (
  <Helmet htmlAttributes={{lang: 'de'}}>
    <meta charSet="utf-8" />
    <title>{title === undefined ? siteTitle : title + ' - ' + siteTitle}</title>
  </Helmet>
);
