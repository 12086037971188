import React from "react";
import styled from "styled-components";
import LogoImg from "./../images/logo.svg";
import { Link } from "gatsby";

const LogoContainer = styled.div`
  flex: 1;
  margin: ${(props) => props.theme.spacing.md} auto;
`;

const StyledLogoImg = styled(LogoImg)`
  max-width: 400px;
  min-width: 180px;
  width: 50%;
  height: auto;
`;

export const Logo = ({}) => (
  <LogoContainer>
    <Link to="/">
      <StyledLogoImg />
    </Link>
  </LogoContainer>
);
